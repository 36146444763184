<template>
  <div class="usersBackGround" id="usersAll">
    <!-- -----------------------------------------------NAVBAR--------------------------------------------- -->
    <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle">
        &nbsp; &nbsp; &nbsp; &nbsp;DDC IoT Platform</text
      >

      <div class="container">
        <div class="item">
          <img :src="require('../../assets/logo2.png')" />
        </div>
      </div>
      <button
        type="button"
        id="navButtonHomeUsers"
        class="btn btn-success"
        @click="navToHome"
      >
        <i class="fa-solid fa-house"></i>
        <text class="navText"> Home</text>
      </button>
      <button
        type="button"
        id="navButtonDomainsUsers"
        class="btn btn-success"
        @click="navToDomains"
      >
        <i class="fa-solid fa-layer-group"></i>
        <text class="navText"> Domains</text>
      </button>
      <button
        type="button"
        id="navButtonDevicesUsers"
        class="btn btn-success"
        @click="navToDevices"
      >
        <i class="fas fa-hdd"></i> <text class="navText"> Devices</text>
      </button>
      <button
        type="button"
        id="navButtonActiveUsers"
        class="btn btn-success"
        @click="navToUsers"
      >
        <i class="fa-solid fa-users"></i> <text class="navText"> Users</text>
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButtonUsers"
          @click="profilename"
        >
          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
          <label class="usernameText">{{ this.user }}</label>

          <i class="fa-solid fa-caret-down" id="pIcon"></i> &nbsp;
        </button>
        <div class="dropdown-menu">
          <a @click="navToSettings" class="dropdown-item" id="blockedContent"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i
          ></a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <!-------------------------------------------------USER CARD--------------------------------------------- -->
    <div class="usersCard" id="userContainer" v-show="this.user=='ewgtestuser1' || this.user=='ewgtestuser2'">You have no permission to this page!</div>
    
    <div class="usersCard" id="userContainer" v-show="this.user!='ewgtestuser1' && this.user!='ewgtestuser2'">
      
      <h4 id="usersTitle">Users</h4>
      <h4 id="userError">{{ error }}</h4>
      
      <!-------------------------------------------------CREATE BUTTON & MODAL--------------------------------------------- -->
      <p>
        <button
          id="userCreateButton"
          type="button"
          class="btn btn-outline-success"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCreate"
        >
          <i class="fas fa-plus"></i>
          <text id="hiddenCreateText"> Create </text>
        </button>
      </p>
      <div
        class="modal fade"
        id="exampleModalCreate"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createUserTitle" >Create User</h2>
              <h5 class="modal-title" id="exampleModalLabel"></h5>
            </div>
            <div class="modal-body">
              
                Please fill the form if you want to create a new user
            <br/>
              <label  for="name" style="float:left">Username:</label>
              <input
                
                v-model="createName"
                type="text"
                name="Name"
                id="userName"
                placeholder="Name"
                @keydown="isCreateEnter"
              />
              <br />
              <br />
              <label  for="password" style="float:left"
                >Password:</label
              >
              <input
                
                v-model="createPassword"
                type="password"
                id="userPassword"
                placeholder="password"
                @keydown="isCreateEnter"
              />
              <button @click="showPassword" class="eyeButtonUserCreate">
                <i class="far fa-eye" id="eyeIcon"></i>
                <i class="far fa-eye-slash" id="slashedEye"></i>
              </button>

              <br />
              <br />
              <label  for="passwordagain" style="float:left"
                >Password again:</label
              >
              <input
                
                v-model="createPasswordAgain"
                type="password"
                id="userPasswordAgain"
                placeholder="password"
                @keydown="isCreateEnter"
              /><button @click="showPasswordAgain" class="eyeButtonUserCreate2">
                <i class="far fa-eye" id="eyeIcon1"></i>
                <i class="far fa-eye-slash" id="slashedEye1"></i>
              </button>
              <br />
              <br />
              <label  for="info" style="float:left">Info:</label>
              <input
                v-model="createInfo"
                type="text"
                name="Info"
                id="userInfo"
                placeholder="Information"
                @keydown="isCreateEnter"
              />
              <br />
              <br />
            </div>
            <div class="modal-footer">
              <div class="footerButtons">
                <button
                  type="button"
                  id="sureButtonCreate"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  @click="createUser()"
                >
                  Save
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="cancelButton"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-------------------------------------------------TABLE--------------------------------------------- -->
      <table class="table table-dark" id="usersTable">
        <tbody>
          <tr>
            <td></td>
            <th id="tableCell">Name</th>
            <th id="tableCell">Info</th>
            <th id="tableCell">Uuid</th>
            <th id="tableCell"></th>
          </tr>
          <tr
            class="usersTableRow"
            v-for="(item, index) in num"
            :key="item.uuid"
          >
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
            </td>
            <td id="tableCell">{{ names[index] }}</td>
            <td id="tableCell">{{ info[index] }}</td>
            <td id="tableCell">{{ uuid[index] }}</td>
            <td id="tableCell">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                id="userDeleteBtn"
                @click="setIndexUsersValue(item, index)"
              >
                <i class="fas fa-trash-alt" id="trashIcon"></i>
                <text id="hiddenDeleteText">&nbsp;Delete</text>
              </button>

              <!-------------------------------------------------DELETE MODAL--------------------------------------------- -->
              <div
                class="modal fade"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </div>
                    <div class="modal-body">
                      Are you sure you want to delete "{{
                        names[indexUsersValue]
                      }}" user?
                    </div>
                    <div class="modal-footer">
                      <div class="footerButtons">
                      <button
                        type="button"
                        id="sureButtonDelete"
                        class="btn btn-success"
                        data-bs-dismiss="modal"
                        @click="deleteUser(itemUsersValue, indexUsersValue)"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="btn btn-danger"
                        id="cancelButton"
                      >
                        No
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
     <div id="spinner-users" class="spinner-container">
     <atom-spinner
          :animation-duration="1000"
          :size="120"
          :color="'#42ba85'"
          :position="absolute"
     />
  </div>
  </div>
</template>

<script>
import ddcIotAuthProvider from "../../utils/ddcIotAuthProvider.js";

import {AtomSpinner} from 'epic-spinners'

const apiUrl = process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";
const authProvider = ddcIotAuthProvider(apiUrl);
import ddcIotDataProvider from "../../utils/ddcIotDataProvider";
const dataProvider = ddcIotDataProvider(apiUrl);

export default {
  ///Variables u can declare
  components:{
    AtomSpinner
  },
  data() {
    return {
      //--------CREATE DATA-------
      createName: null,
      createPassword: null,
      createPasswordAgain: null,
      createInfo: null,
      createError: null,
      createArray: [],
      //--------
      names: [],
      uuid: [],
      info: [],
      indexUsersValue: null,
      num: 0,
      error: null,
      user: localStorage.getItem("username"),
    };
  },

  mounted() {
    document.getElementById("spinner-users").style.display = "block";
    this.themeChanger();
    //console.log("mounted!");

    dataProvider
      .getList("users")

      .then((res) => {
        return (
          res.data.map((data) => {
            this.uuid.push(data.uuid);
            this.names.push(data.username);
            this.info.push(data.info);
            document.getElementById("spinner-users").style.display = "none";
          }),
          (this.num = res.data)
          
        );
      })
      .catch((err) => console.log(err));
  },

  updated() {
    //console.log("updataed!");
  },

  ///Functions u can call in. For example do something when a button is pushed.
  ///To reach data from data() where you declare variables use this.something
  methods: {
    isCreateEnter() {
      if (event.keyCode == 13) {
        document.getElementById("sureButton").click();
      }
    },
    //------------------------------------NAVBAR METHODS-----------------------------------------------//
    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
      //console.log(this.loggedIn);
      localStorage.setItem("username", "");
    },
    profilename() {
      //console.log(localStorage.getItem("username"));
    },

    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    showPassword() {
      if (document.getElementById("userPassword").type == "password") {
        document.getElementById("userPassword").type = "text";
        document.getElementById("eyeIcon").style.display = "none";
        document.getElementById("slashedEye").style.display = "block";
      } else if (document.getElementById("userPassword").type == "text") {
        document.getElementById("userPassword").type = "password";
        document.getElementById("eyeIcon").style.display = "block";
        document.getElementById("slashedEye").style.display = "none";
      }
    },

    showPasswordAgain() {
      if (document.getElementById("userPasswordAgain").type == "password") {
        document.getElementById("userPasswordAgain").type = "text";
        document.getElementById("eyeIcon1").style.display = "none";
        document.getElementById("slashedEye1").style.display = "block";
      } else if (document.getElementById("userPasswordAgain").type == "text") {
        document.getElementById("userPasswordAgain").type = "password";
        document.getElementById("eyeIcon1").style.display = "block";
        document.getElementById("slashedEye1").style.display = "none";
      }
    },

    setIndexUsersValue(item, index) {
      this.indexUsersValue = index;
      this.itemUsersValue = item;
    },
    //------------------------------------ROUTING-----------------------------------------------//
    navToDomains() {
      this.$router.push("/domains");
    },

    navToSettings() {
      this.$router.push("/settings");
    },

    navToUsers() {
      this.$router.push("/users");
    },
    //------------------------------------CREATE-----------------------------------------------//
    async createUser() {
      this.error = "";
      if (this.createPassword != this.createPasswordAgain) {
        this.error = "Passwords did not match";
        return;
      }

      let res = await dataProvider.getList("users");
      //console.log(res);
      let wrong = false;
      for await (let rows of res.data) {
        //console.log(rows);
        if (rows.username == this.createName) {
          wrong = true;
        }
      }
      if (wrong) {
        this.error = "User already exists";
      } else {
        var params = {
          data: {
            name: this.createName,
            password: this.createPassword,
            info: this.createInfo,
          },
        };
        await dataProvider.create("users", params);
        //console.log("Create User!");
        const newArray = await dataProvider.getList("users");
        //console.log(newArray);
        this.names = [];
        this.uuid = [];
        this.info = [];
        for (let rows of newArray.data) {
          //console.log(rows);
          this.names.push(rows.username);
          this.uuid.push(rows.uuid);
          this.info.push(rows.info);
        }
        this.num = newArray.data;
        //console.log(this.names, this.uuid, this.info);
      }
    },
    //------------------------------------DELETE-----------------------------------------------//
    deleteUser(params, index) {
      //console.log(params);
      var param = {
        id: params.uuid,
      };
      if (params.username != "admin") {
        //console.log("Delete user!");
        dataProvider.delete("users", param).then(() => {});
        this.num.splice(index, 1);
        this.names.splice(index, 1);
        this.uuid.splice(index, 1);
        this.info.splice(index, 1);
        //console.log(this.num);
        if (params.username == localStorage.getItem("username")) {
          this.logout();
        }
      } else {
        this.error = "Cannot delete Admin user";
      }
    },
    //------------------------------------THEME CHANGER-----------------------------------------------//
    themeChanger() {
      var themeValue = localStorage.getItem("theme");

      if (themeValue == "light") {
        document.getElementById("usersAll").style.backgroundColor = "white";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDomains").style.color = "black";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDevices").style.color = "black";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonHome").style.color = "black";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#212529";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#d2d3db";
        document.getElementById("logoutButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("logoutButton").style.color = "black";
        document.getElementById("userContainer").style.backgroundColor =
          "#d2d3db";
        document.getElementById("userContainer").style.color = "black";
        document.getElementById("logo").style.color = "black";
        document.getElementById("userCreateButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("userCreateButton").style.color = "black";
        document.getElementById("userCreateButton").style.border =
          "1px solid black";
        document.getElementById("usersTable").className = "table";
        document.getElementById("usersTable").style.color = "black";
      }

      if (themeValue == "blue") {
        document.getElementById("usersAll").style.backgroundColor = "white";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDomains").style.color = "white";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDevices").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "dodgerblue";
        document.getElementById("logoutButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("userContainer").style.backgroundColor =
          "dodgerblue";
        document.getElementById("userContainer").style.color = "white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("userCreateButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("userCreateButton").style.color = "white";
        document.getElementById("userCreateButton").style.border =
          "1px solid white";
        document.getElementById("usersTable").className = "table";
        document.getElementById("usersTable").style.color = "white";
      }

      if (themeValue == "green") {
        document.getElementById("usersAll").style.backgroundColor = "white";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDomains").style.color = "white";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDevices").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#42ba85";
        document.getElementById("logoutButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("userContainer").style.backgroundColor =
          "#42ba85";
        document.getElementById("userContainer").style.color = "white";
        document.getElementById("userCreateButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("userCreateButton").style.color = "white";
        document.getElementById("userCreateButton").style.border =
          "1px solid white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("usersTable").className = "table";
        document.getElementById("usersTable").style.color = "white";
      }

      if (themeValue == "cyan") {
        document.getElementById("usersAll").style.backgroundColor = "#2a323d";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDomains").style.color = "#2a323d";
        document.getElementById("navButtonDevices").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDevices").style.color = "#2a323d";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonHome").style.color = "#2a323d";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#2a323d";
        document.getElementById("navButtonActive").style.color = "#159fba";
        document.getElementById("appbar").style.backgroundColor = "#159fba";
        document.getElementById("logoutButton").style.backgroundColor =
          "#159fba";
        document.getElementById("logoutButton").style.color = "#2a323d";
        document.getElementById("userContainer").style.backgroundColor =
          "#159fba";
        document.getElementById("userContainer").style.color = "#2a323d";
        document.getElementById("userCreateButton").style.backgroundColor =
          "#2a323d";
        document.getElementById("userCreateButton").style.color = "#159fba";
        document.getElementById("userCreateButton").style.border =
          "1px solid #159fba";
        document.getElementById("logo").style.color = "#2a323d";
        document.getElementById("usersTable").className = "table";
        document.getElementById("usersTable").style.color = "#2a323d";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/*------------------------------------------ CSS -----------------------------------------*/

#hiddenTitle {
  font-size: 20px;
  position: absolute;
  top: 0px;
}

#spinner-users{
    position: absolute;
  top: 50vh;
  left: 50vw;
  display:none;
}

#appbar {
  min-height: 50px;
  max-height: 60px;
}
/*----------------------- Navbar ---------------------*/
/*----------- Home -------------*/
#navButtonHomeUsers {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
   margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonHomeUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHomeUsers:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Domains -------------*/
#navButtonDomainsUsers {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
   margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}
#sureButton {
  background-color: #42ba85;
  border: 0;
  width:110px;
  max-width:150px;
  min-width:110px;
  float:left;
}
#sureButtonCreate {
  background-color: #42ba85;
  border: 0;
  width:110px;
  max-width:150px;
  min-width:110px;
  float:left;
}
#sureButtonDelete {
  background-color: #42ba85;
  border: 0;
  width:110px;
  max-width:150px;
  min-width:110px;
  float:left;
}

#navButtonDomainsUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsUsers:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Devices -------------*/

#navButtonDevicesUsers {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
   margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDevicesUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#cancelButton {
 position: relative;
  float: right;
margin-left:3px;
  width:110px;
  max-width:150px;
  min-width:110px;
  
}

#cancelButton:hover {
  color: white;
  background-color: #bb2d3b;
}


#navButtonDevicesUsers:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Users -------------*/

#navButtonActiveUsers {
  background-color: #42ba85;
  color: black;
  border: 0;
  position: relative;
  left: 0px;
   margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDomainsUsers:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsUsers:hover {
  background-color: #42ba85;
  color: black;
}
/*----------- User, logout -------------*/

#logoutButtonUsers {
  position: fixed;
  float: right;
  right: 30px;
  background-color: #20262e;
  display: flex;
  top: 0.1vh;
  width: 7%;
  border: 0;
  cursor: pointer;
   min-width: 150px;
  max-width: 200px;
}

#logoutButtonUsers:hover {
  color: #42ba85;
}

#logoutButtonUsers:focus {
  outline: none;
  box-shadow: none;
}


td {
  font-size: clamp(12px, 1.5vw, 1rem);
}
.modal-body{
   font-size:  clamp(12px, 1.5vw, 1rem);
  }

.table {
  cursor: auto;
}
#userError{
 position: relative;
  left: 1vw;
  top: 3.5vh;
  color:#dc3545;
  font-size:20px;
}
#slashedEye1 {
  display: none;
  height:35px;
}

#slashedEye {
  display: none;
  height:35px;
}

.usersTableRow:hover {
  color: grey;
}

.formStuffuser {
  left: 10px;
  position: relative;
  padding: 2px;
  font-size: 1rem;
}

#eyeSlashIcon {
  display: none;
  height:35px;
}

.usernameText {
  margin-right: 15px;
}

#hiddenDeleteText {
  bottom: 0.5vh;
}

#trashIcon {
  bottom: 0.5vh;
}

#userName {
 position: relative;
  float: right;
  margin-right: 6rem;
}

#userPassword {
  position: relative;
  float: right;
  margin-right: 6rem;
}

#userPasswordAgain {
  position: relative;
  float: right;
  margin-right: 6rem;
}

#userInfo {
 position: relative;
  float: right;
  margin-right: 6rem;
}

.usersCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 95vw;

  border-radius: 5px;
  top: 12vh;
  left: 0px;
  background-color: #212529;
  color: white;
  margin: auto;

  position: relative;
}
.usersCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

#flexCheckDefault {
  background-color: #2a323d;
  border: 1px solid #42ba85;
}
.btn {
  width: 7vw;
  min-width: 75px;
  float: right;
  
}
.btn-danger {
  background-color: #dc3545;
  margin-right: 5px;
   width: 7vw;
  float: right;
  max-width: 150px;
   min-width: 75px;
    min-width:110px;
}

.btn-success {
  border: 0px solid red;
  background-color: #42ba85;
  float: left;
  
}

.uuid {
  width: 70vh;
}
.pageTitle {
  padding: 1vh;
}
.checkbox {
  width: 2vh;
}

#usersTitle {
  position: relative;
  left: 10px;
  top: 35px;
  width: 50%;
}

#userCreateButton {
  background-color: #2a323d;
  color: #42ba85;
  right: 12px;
  position: relative;
  border-radius: 5px;
  margin: 3px;
  width: 105px;
  width: 110px;
  max-width: 150px;
  min-width: 110px;
}

#userCreateButton:hover {
  color: #2a323d;
  background-color: #42ba85;
}

.usersBackGround {
  background-color: #2a323d;
  width: 100vw;
   height: 100vh;
  overflow-y: visible;
  overflow-x: hidden;
}

#tableCell {
  cursor: auto;
}

.eyeButtonUserCreate {
  position: relative;
  background-color: transparent;
  border: 0;
  left:185px;
  float: right;
  height:35px;
  bottom:3px;
}
.eyeButtonUserCreate2 {
  position: relative;
  background-color: transparent;
  border: 0;
  float: right;
  left:185px;
  height:35px;
  bottom:3px;
}
.footerButtons {
  margin: auto;
}
/*----------------------------- Logo ---------------------------*/

* {
  box-sizing: border-box;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-top: 2.5px;
  margin-left: 10px;
}
.item {
  align-items: center;
  display: flex;
  height: 7.5px;
  justify-content: center;
  position: relative;
  width: 7.5px;
  z-index: 100;
  img {
    object-fit: cover;
    height: 45px;
    width: 45px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #0076a3 0%, #072b3c 97%);
    content: "";
    height: 7.5px;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 7.5px;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #8c9a27 0%, #d3d821 97%);
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.4s;
    &::before {
      animation: slick-hover-2 3s 0.4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after {
      animation: slick-hover 3s 0.4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(0.9);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}

@media (max-width: 800px) and (min-width: 501px) {
#hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonUsers {
    right: 0px;
  }
  #hiddenCreateText {
    display: none;
  }
  .eyeButtonUserCreate{
left:140px;
  }
  .eyeButtonUserCreate2{
left:140px;
  }

  /*----------- Home -------------*/
  #navButtonHomeUsers {
   font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsUsers {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
  #navButtonDevicesUsers {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
  #navButtonActiveUsers {
   font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonUsers {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
    
  }
  #hiddenDeleteText {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 450px) {
  td{
  font-size:  clamp(12px, 1.5vw, 1rem);
}
.modal-body{
    font-size:  clamp(12px, 1.5vw, 1rem);
  }
#hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navIcon {
    left: 7px;
    position: relative;
  }

  #navButtonHomeUsers {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonDevicesUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonDomainsUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonActiveUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #profileIcon {
    position: relative;
  }

  #logoutButtonUsers {
    position: relative;
    float: left;
    right: 65%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  .usernameText {
    display: none;
  }

  .usersCard {
     margin:auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #usersTable {
 max-width: 85vw;
   
  }
  #tableCell {
    font-size: 12px;
    
  }
  
  #hiddenDeleteText {
    display: none;
  }
  #userDeleteBtn {
 min-width:150%;
   float:left;
  
  }
 
  #userCreateButton {
     min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right:5%;
  }
  #hiddenCreateText {
    display: none;
  }
  #userName{
    left:180px;
    float: right;
    max-width:120px;
    position:absolute;
  }
  #userPassword{
   left:180px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  #userPasswordAgain{
    left:180px;
    float: right;
   max-width:120px;
    position:absolute;
  }
  #userInfo{
    left:180px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  .eyeButtonUserCreate{
  bottom: 94px;
   left: 275px;
  position: absolute;
   z-index: 1;
  }
  .eyeButtonUserCreate2{
  bottom: 59px;
   left: 275px;
   position: absolute;
   z-index: 1;
  }
}

@media (max-width: 450px) and (min-width: 350px) {
  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navIcon {
    left: 7px;
    position: relative;
  }

  #navButtonHomeUsers {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonDevicesUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonDomainsUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #navButtonActiveUsers {
    font-size: 100%;
    min-width: 10%;
  }

  #profileIcon {
    position: relative;
  }

  #logoutButtonUsers {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  .usernameText {
    display: none;
  }

  .usersCard {
     margin:auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #usersTable {
 max-width: 85vw;
   
  }
  #tableCell {
    font-size: 8px;
    
  }
  
  #hiddenDeleteText {
    display: none;
  }
  #userDeleteBtn {
 min-width:150%;
   float:left;
  
  }
 
  #userCreateButton {
     min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right:5%;
  }
  #hiddenCreateText {
    display: none;
  }
  #userName{
    left:180px;
    float: right;
    max-width:120px;
    position:absolute;
  }
  #userPassword{
   left:180px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  #userPasswordAgain{
    left:180px;
    float: right;
   max-width:120px;
    position:absolute;
  }
  #userInfo{
    left:180px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  .eyeButtonUserCreate{
  bottom: 94px;
   left: 275px;
  position: absolute;
   z-index: 1;
  }
  .eyeButtonUserCreate2{
  bottom: 59px;
   left: 275px;
   position: absolute;
   z-index: 1;
  }
}
@media (max-width: 1260px) and (min-width: 1010px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonUsers {
    right: 0px;
  }
  #hiddenCreateText {
    display: none;
  }
}
@media (max-width: 1010px) and (min-width: 800px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  #logoutButtonUsers {
    right: 0px;
  }
  #hiddenCreateText {
    display: none;
  }
  .eyeButtonUserCreate{
left:17.5vw;
  }
  .eyeButtonUserCreate2{
left:17.5vw;
  }

  /*----------- Home -------------*/
  #navButtonHomeUsers {
   font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsUsers {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
  #navButtonDevicesUsers {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
  #navButtonActiveUsers {
   font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonUsers {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
    
  }
  #hiddenDeleteText {
    display: none;
  }
  /* .usersCard {
    margin-left: 10px;
    width: 450px;
  } */
}
@media (max-width: 350px){
 #userName{
    left:120px;
    float: right;
    max-width:120px;
    position:absolute;
  }
  #userPassword{
   left:120px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  #userPasswordAgain{
    left:120px;
    float: right;
   max-width:120px;
    position:absolute;
  }
  #userInfo{
    left:120px;
    float: right;
    max-width:120px;
     position:absolute;
  }
  .eyeButtonUserCreate{
  bottom: 53px;
   left: 190px;
  position: absolute;
   z-index: 1;
  }
  .eyeButtonUserCreate2{
  bottom: 33px;
   left: 190px;
   position: absolute;
   z-index: 1;
  }
 
 #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navIcon {
    left: 7px;
    position: relative;
  }

  #navButtonHomeUsers {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  #navButtonDevicesUsers {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  #navButtonDomainsUsers {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  #navButtonActiveUsers {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  #profileIcon {
    position: relative;
  }

  #logoutButtonUsers {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }

  .usernameText {
    display: none;
  }

  .usersCard {
     margin:auto;
    overflow-y: clip;
    overflow-x: clip;
  }
  #usersTable {
 max-width: 85vw;
   
  }
  #tableCell {
    font-size: 8px;
    
  }
  
  #hiddenDeleteText {
    display: none;
  }
  #userDeleteBtn {
 min-width:130%;
   float:left;
 
  }
 
  #userCreateButton {
     min-width: 10%;
    max-width: 40px;
    align-items: center;
    margin-right:7%;
  }
  #hiddenCreateText {
    display: none;
  }
#trashIcon {
  font-size:12px;
}
.modal-body{
   font-size: 7px;
  }
  #sureButton{
    font-size:10px;
    max-width: 80px;
     min-width: 80px;
    width: 80px;
  }
  #cancelButton{
     font-size:10px;
    max-width: 80px;
     min-width: 80px;
    width: 80px;
  }
}
</style>
